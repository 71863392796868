.chat-message-avatar-container {
  display: inline-block;
  padding: 5px;

  /* Default to message on the left */
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
}

.chat-message-right .chat-message-avatar-container {
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}

.chat-message-avatar {
  -webkit-animation: Lmuha 0.3s ease forwards;
  animation: Lmuha 0.3s ease forwards;
  border-radius: 50%;
  height: 40px;
  min-width: 40px;
  padding: 3px;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  width: 40;

  /* Default to message on the left */
  box-shadow: -1px 3px 5px 0px rgba(0, 0, 0, 0.45);
  -webkit-transform-origin: bottom left;
  -ms-transform-origin: bottom left;
  transform-origin: bottom left;
}

.chat-message-right .chat-message-avatar {
  box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.45);
  -webkit-transform-origin: bottom right;
  -ms-transform-origin: bottom right;
  transform-origin: bottom right;
}

.chat-message-content {
  display: inline-block;
  border-radius: 12px;
  -webkit-animation: Lmuha 0.3s ease forwards;
  animation: Lmuha 0.3s ease forwards;
  margin: 5px 0 5px 0;
  position: relative;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);

  /* Default to message on the left */
  box-shadow: -1px 3px 5px 0px rgba(0, 0, 0, 0.45);
  -webkit-transform-origin: bottom left;
  -ms-transform-origin: bottom left;
  transform-origin: bottom left;
}

.chat-message-right .chat-message-content {
  box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.45);
  -webkit-transform-origin: bottom right;
  -ms-transform-origin: bottom right;
  transform-origin: bottom right;
}

.chat-message-container {
  /* Default to message on the left */
  display: flex;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.chat-message-container.chat-message-right {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.chat-message {
  display: flex;
  flex-grow: 1;
  max-width: 95%;
}
.chat-message-container.chat-message-right > .chat-message {
  /* Counters the flex-grow=1 when message is on the right */
  flex-grow: 0;
}

@media screen and (min-width: 576px) {
  .chat-message {
    max-width: 70%;
  }
}

@media screen and (min-width: 992px) {
  .chat-message {
    max-width: 50%;
  }
}

/* TEXT */
.chat-message-text {
  height: 100%;
  text-align: left;
  overflow: hidden;
  word-wrap: break-word;
}

/* IMAGE */
.chat-message-image {
  max-width: 100%;
  width: 100%;
  height: 100%;

  border-radius: 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  display: inline-block;
  line-height: 0;
  position: relative;
}

/* VIDEO */
.chat-message-video {
  flex-grow: 1;
}
.video-react .video-react-poster {
  background-color: unset;
}

/* LIST */
.chat-message-list {
  margin: 0;
  padding-left: 20px;
}

/* NARRATOR */
.chat-message-narrator .chat-message-content {
  /* Obligé de passer par le CSS car plusieurs valeurs de width */
  width: 100%;
  width: -moz-available;
  width: -webkit-stretch;
  width: stretch;
}
