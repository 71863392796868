/* CONTAINER */
.chat-input {
  position: relative;
  width: 100%;
}

/* SUBMIT */
.ui.button.chat-input-submit {
  position: absolute;
  margin: auto;
  right: 0;
  bottom: 0;
  top: 0;

  background-color: transparent;
  font-size: 1.3rem;
}

/* TEXT */
.chat-input-text {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  box-sizing: border-box;
  font-size: 16px;
  outline: none;
  width: 100%;
  -webkit-appearance: none;
}

.chat-input-text-container {
  padding: 16px 52px 16px 10px;
}

/* OPTIONS */
.chat-input-options-container {
  padding: 6px 10px 6px 10px;
}
.chat-input-options {
  margin: 0;
  padding: 0;
}
.chat-input-options-submit {
  margin-right: 45px;
}

.chat-input-option {
  display: inline-block;
  margin: 3px;
  cursor: pointer;

  animation: Lmuha 0.3s ease forwards;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.chat-input-option-element {
  display: inline-block;
}

.chat-input-option-element:hover {
  opacity: 0.7;
}

/* SELECT POPUP */
.chat-input-selectpopup-container {
  padding: 9px 10px 9px 10px;
}
.chat-input-selectpopup-modal-container-submit {
  margin-right: 40px;
}

.chat-input-selectpopup-button {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ui.modal.chat-input-selectpopup-modal > .actions {
  text-align: center;
}

.chat-input-selectpopup-options {
  margin: 0;
  padding: 0;
}

.chat-input-selectpopup-option {
  display: block;
  margin: 3px;
  cursor: pointer;

  animation: Lmuha 0.3s ease forwards;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.chat-input-selectpopup-option-element {
  display: inline-block;
}

.chat-input-selectpopup-option-element:hover {
  opacity: 0.7;
}

/* ACTIONS */
.chat-input-actions-container {
  padding: 6px 10px 6px 10px;
}
.chat-input-actions {
  margin: 0;
  padding: 0;
}

.chat-input-action {
  display: inline-block;
  margin: 3px;
  cursor: pointer;

  animation: Lmuha 0.3s ease forwards;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.chat-input-action-element {
  display: inline-block;
}

.chat-input-action-element:hover {
  opacity: 0.7;
}
