.game-resume-header {
  position: relative;
  margin-top: 10px;
  margin-bottom: 5px;
}

.game-resume-subheader {
  padding: 10px;
}
.game-resume-subheader-image {
  max-width: 100%;
  max-height: 25vh;
  min-height: 100px;

  border-radius: 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.game-resume-subheader-text {
  padding-top: 10px;
}

.game-card {
  margin: 0 !important;
}
.game-card > .ui.card {
  margin: 0.5em 0.5em;
}
.game-card > .ui.card > .content {
  padding: 0.5em;
}
.game-card > .ui.card.disabled {
  opacity: 0.45 !important;
}
